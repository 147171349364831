import React from "react";
import Link from "next/link";
import { RoutesOption } from "../routes";
import { colors } from "../utils/style";

export default function FourOhFour() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: colors.bg.primary,
        color: colors.text.primary,
        width: "100vw",
        height: "100vh",
      }}
    >
      <h2>{"404 - Not Found"}</h2>
      <p>
        {"Please return "}
        <Link href={RoutesOption.Home}>
          <a style={{ color: colors.text.accent }}>Home</a>
        </Link>
      </p>
    </div>
  );
}
